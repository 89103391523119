import React,{useEffect, useState} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import {saveContact} from "../../services/Contacts";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Contact() {

    // eslint-disable-next-line no-unused-vars
    const { formState, register, handleSubmit, errors } = useForm();
    // eslint-disable-next-line no-unused-vars
    const [showError, setShowError] = useState(false);

    const onSubmit = (form) => {
        toast.dismiss();
        // Call your method and pass the form data
        saveContact(form, "contact-us").then((res) => {
            console.log(res);
            toast.success("Your message was sent successfully");
        }).catch((err) => {
            console.log(err)
        });
    };
    const onErrors = errors => {
        if(errors) {
            setShowError(true);
            setTimeout(() => {
                setShowError(false);
            }, 3000);
        }
        toast.error(errors?.name?.message);
        toast.error(errors?.email?.message);
        toast.error(errors?.company?.message);
    }

    useEffect(()=>{
        AOS.init({duration:1300})


    },[])
  return (
    <section className="contact-area page-section scroll-content" id="contact" >
        <ToastContainer />
        <div className="custom-container">
            <div className="contact-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-envelope"></i> contact
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>Let's Work <span>Together!</span></h1>
                </div>
                {/*<h3 className="scroll-animation" data-aos='fade-up'>namaste@sherpalabs.in</h3>*/}
                {/* {showToast && <h3 className="scroll-animation toast-success" data-aos='fade-up'>Your details successfully saved.</h3>} */}
                {/* {showError && <p id="required-msg show">* Marked fields are required to fill.</p>} */}

                <form className="contact-form scroll-animation" data-aos='fade-up' method="POST" onSubmit={handleSubmit(onSubmit, onErrors)}>
                    {/* <div className="alert alert-success messenger-box-contact__msg" style={{display: 'none'}} role="alert">
                        Your message was sent successfully.
                    </div> */}
                    <div className="row">
                        <div className="col-md-6">
                            <div className="input-group">
                                <label for="full-name">full Name <sup>*</sup></label>
                                <input type="text" {...register("name", { required: "Name is required" })} id="full-name" placeholder="Your Full Name"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-group">
                                <label for="email">Email <sup>*</sup></label>
                                <input type="email" {...register("email", { required: "Email is required" })} id="email" placeholder="Your email adress"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-group">
                                <label for="phone-number">Phone <span>(optional)</span></label>
                                <input type="text" {...register("mobileNo", { required: false})} id="phone-number" placeholder="Your number phone"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-group">
                                <label htmlFor="Company">Your Company <sup>*</sup></label>
                                <input type="text" {...register("company", { required: "Company is required" })} id="company" placeholder="Your company name"/>
                            </div>
                        </div>
                        {/*<div className="col-md-12">*/}
                        {/*    <div className="input-group">*/}
                        {/*        <label for="budget">your budget <span>(optional)</span></label>*/}
                        {/*        <input type="number" name="budget" id="budget" placeholder="A range budget for your project"/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="col-md-12">*/}
                        {/*    <div className="input-group">*/}
                        {/*        <label for="message">message</label>*/}
                        {/*        <textarea name="message" id="message" placeholder="Wrire your message here ..."></textarea>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="col-md-12">*/}
                        {/*    <div className="input-group upload-attachment">*/}
                        {/*        <div>*/}
                        {/*            <label for="upload-attachment">*/}
                        {/*                <i className="las la-cloud-upload-alt"></i> add an attachment*/}
                        {/*                <input type="file" name="file" id="upload-attachment"/>*/}
                        {/*            </label>*/}

                        {/*        </div>*/}

                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="col-md-12">
                            <div className="input-group submit-btn-wrap">
                                <button className="theme-btn" name="submit" type="submit" id="submit-form">send message</button>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </section>
  )
}
