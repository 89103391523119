import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Program() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="resume-area page-section scroll-to-page" id="program">
        <div className="custom-container">
            <div className="resume-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-briefcase"></i>Training Program
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>Our Training Program</h1>
                </div>

                <div className="resume-timeline">
                    <div className="item scroll-animation" data-aos='fade-right'>
                        <span className="date">Step 1</span>
                        <h2>Take our coding challenge</h2>
                        <p>Full stack development</p>
                        <h2>Your preferred language</h2>
                        <p>Follow our guidelines</p>
                    </div>
                    <div className="item scroll-animation" data-aos='fade-left'>
                        <span className="date">Step 2</span>
                        <h2>Free assessment of your code</h2>
                        <p>Counselling session with our CTO</p>
                        <h2>Tips to improve</h2>
                        <p>Clean Code & best practises</p>
                    </div>
                    <div className="item scroll-animation" data-aos='fade-right'>
                        <span className="date">Step 3</span>
                        <h2>Guidance on ideal path to pursue & next steps</h2>
                        <p>Personalized training</p>
                    </div>
                    <div className="item scroll-animation" data-aos='fade-left'>
                        <span className="date">Step 4</span>
                        <h2>Attend our immersive training program</h2>
                        <p>Opportunity to get placed in a tech startup</p>
                    </div>
                </div>

            </div>
        </div>
    </section>
  )
}
