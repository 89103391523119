import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Service() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="services-area page-section scroll-to-page" id="services">
        <div className="custom-container">
            <div className="services-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-stream"></i> Services
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>Our offering to Startups</h1>
                </div>

                <div className="services-items">
                    <div className="service-item scroll-animation" data-aos='fade-up'>
                        <i className="las la-search"></i>
                        <h2>Technical Review</h2>
                        <ul>
                        <li>Architecture</li>
                        <li>Codebase</li>
                        <li>Software Systems</li>
                        </ul>
                    </div>
                    <div className="service-item scroll-animation" data-aos='fade-up'>
                        <i className="las la-code"></i>
                        <h2>Product Development</h2>
                        <ul>
                        <li>Web & Mobile Applications</li>
                        <li>Emerging Technology & Generative AI</li>
                        <li>Web 3.0</li>
                        </ul>

                    </div>
                    <div className="service-item scroll-animation" data-aos='fade-up'>
                        <i className="las la-chalkboard"></i>
                        <h2>Training & Staffing</h2>
                        <ul>
                        <li>Coding Excellence</li>
                        <li>Agile Best Practises</li>
                        <li>Delivery & Communication</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
