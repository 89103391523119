import React from 'react'

export default function Lsb() {
  return (
        <div className="left-sidebar">
            <div className="sidebar-header d-flex align-items-center justify-content-between">
                {/* <img src="./assets/images/logo.png" alt="Logo"/> */}
                {/* <span className="designation">Your Trusted Partner</span> */}
            </div>
            <img className="me" src="./assets/images/logo-no-bg.png" alt="Me"/>
            <h2 className="address">A software engineering partner</h2>
            <p className="copyright">&copy; 2023 Sherpa Labs. All Rights Reserved</p>
            <ul className="social-profile d-flex align-items-center flex-wrap justify-content-center">
                <li className="">
                    <a target="_blank" href="https://www.linkedin.com/company/sherpa-labs/"><i className="lab la-linkedin"></i></a>
                </li>
                <li>
                    <a target="_blank" href="https://www.youtube.com/@SherpaLabs"><i className="lab la-youtube"></i></a>
                </li>
                <li>
                    <a target="_blank" href="https://github.com/sherpalabs"><i className="lab la-github"></i></a>
                </li>
            </ul>
            <a href="#contact" className="theme-btn">
                <i className="las la-envelope"></i> Reach us!
            </a>
        </div>
  )
}
