import './assets/css/bootstrap.min.css'
import './css/App.css'
import './css/responsive.css'
import Global from './components/Global'

import { Route, Routes } from 'react-router-dom';
import GlobalDeveloper from "./components/GlobalDeveloper";

const App = () => {
  return (
      <div>
        <Routes>
          <Route exact path="/" element={<Global/>} />
          <Route path="/developers" element={<GlobalDeveloper/>} />
        </Routes>
      </div>
  );
};


export default App;
