import React from 'react'

export default function Scrollnav() {
  return (
    <ul className="menu scroll-nav d-flex no-style-list">
        <li>
            <a className="scroll-to" href="#home">
                <span>Home</span> <i className="las la-home"></i>
            </a>
        </li>
        <li>
            <a className="scroll-to" href="#why">
                <span>Engagement</span> <i className="las la-user"></i>
            </a>
        </li>
        <li>
            <a className="scroll-to" href="#services">
                <span>Services</span> <i className="las la-stream"></i>
            </a>
        </li>
        <li>
            <a className="scroll-to" href="#partner">
                <span>A Partner</span> <i className="las la-shapes"></i>
            </a>
        </li>
        {/*<li>*/}
        {/*    <a className="scroll-to" href="#training">*/}
        {/*        <span>Training Program</span> <i className="las la-school"></i>*/}
        {/*    </a>*/}
        {/*</li>*/}
        <li>
            <a className="scroll-to" href="#engagement">
                <span>Engagement Model</span> <i className="las la-building"></i>
            </a>
        </li>
        <li>
            <a className="scroll-to" href="#clients">
                <span>Clients</span> <i className="las la-grip-vertical"></i>
            </a>
        </li>
        {/*<li>*/}
        {/*    <a className="scroll-to" href="#testimonial">*/}
        {/*        <span>Testimonial</span> <i className="lar la-comment"></i>*/}
        {/*    </a>*/}
        {/*</li>*/}
        <li>
            <a className="scroll-to" href="#contact">
                <span>Contact</span> <i className="las la-envelope"></i>
            </a>
        </li>
    </ul>
  )
}
