import React from 'react'
import Lsb from './Global-content/Lsb'
import Program from "./Main-Components/Program";
import TrainingExperience from "./Main-Components/training-experience";
import ContactTraining from "./Main-Components/Contact-training";
import HerosecTraining from "./Main-Components/Herosec-training";
import {Link} from "react-router-dom";

export default function DeveloperWrapper() {
  return (
    <main className="drake-main">
        <div id="smooth-wrapper">
            <div id="smooth-content">

                <Lsb/> {/* this component will used by mobile devices */}

                <HerosecTraining/>
                <Program/>
                <TrainingExperience/>
                <ContactTraining/>


                <section className="hero-section page-section scroll-to-page">
                    <div className="custom-container">
                        <div className="hero-content content-width">
                            <div className="section-header">
                                <Link to="/" target="_blank">
                                    <h2 className="subtitle scroll-animation "
                                        data-animation="fade_from_bottom" data-aos='fade-up'>
                                        <i className="las la-home"></i> For STARTUPS
                                    </h2>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </div>
    </main>
  )
}
