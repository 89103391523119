import React from 'react'

export default function ScrollnavDeveloper() {
  return (
    <ul className="menu scroll-nav d-flex no-style-list">
        <li>
            <a className="scroll-to" href="#home">
                <span>Home</span> <i className="las la-home"></i>
            </a>
        </li>
        <li>
            <a className="scroll-to" href="#program">
                <span>Program</span> <i className="las la-briefcase"></i>
            </a>
        </li>
        <li>
            <a className="scroll-to" href="#experience">
                <span>An Experience</span> <i className="las la-stream"></i>
            </a>
        </li>
        <li>
            <a className="scroll-to" href="#contact-training">
                <span>Contact</span> <i className="las la-envelope"></i>
            </a>
        </li>
    </ul>
  )
}
