import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore/lite';


class Firebase {
    constructor() {
        // Initialize Firebase
        this.app = initializeApp({
            apiKey: process.env.REACT_APP_API_KEY,
            authDomain: process.env.REACT_APP_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_DATABSE_URL,
            projectId: process.env.REACT_APP_PROJECT_ID,
            storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_APP_ID,
            measurementId: process.env.REACT_APP_MEASUREMENT_ID
        });
        this.db = getFirestore(this.app);
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Firebase();
