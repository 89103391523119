import { collection, addDoc } from 'firebase/firestore/lite';
import firebase from './Firebase';

const saveContact = (contact, dbName) => {
    return new Promise(async resolve => {
        await addDoc(collection(firebase.db, dbName), contact);

        resolve();
    })
}



export { saveContact };
