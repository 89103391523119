import React, { useState } from 'react'
import Video from './Global-content/Video'
import Lsb from './Global-content/Lsb'
import DeveloperWrapper from "./DeveloperWrapper";
import ScrollnavDeveloper from "./Global-content/Scrollnav-developer";
import RsidemenuDeveloper from "./Global-content/Rsidemenu-developer";


export default function GlobalDeveloper(){
    const [currentVideo,setVideo]=useState('')

    // eslint-disable-next-line no-unused-vars
    function changeVideo(videos){
      setVideo(videos);
    }
    return (
      <div>
        <Video video={currentVideo}/>
      {/* <Pageloader/> */}

        {/* Settings icon */}
        {/*<Settings clickEvent={changeVideo} />*/}

        {/* Left Side Bar and it will get executed on large devices*/}
        <Lsb/>

        {/*when user interacts with the hamburger menu it display the inner-components*/}
        <RsidemenuDeveloper/>

        {/*fixed on large screens easy to navigate through section og main components*/}
        <ScrollnavDeveloper/>

        {/* heroSection, about, testimoni  */}
        <DeveloperWrapper/>

    </div>
    )
  }
