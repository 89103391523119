import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Clientlogos() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="resume-area page-section scroll-to-page" id="clientlogo">
        <div className="custom-container" id="clients">
            <div className="services-content content-width">
                <div class="clients-logos">
                    <div className="section-header">
                        {/*<h4 className="subtitle scroll-animation" data-aos='fade-up'>*/}
                        {/*    <i className="las la-grip-vertical"></i> Clients*/}
                        {/*</h4>*/}
                        <h1 className="scroll-animation" data-aos='fade-up'>Our <span>Credentials</span></h1>
                    </div>
                    <h4 class="scroll-animation logo-text" data-aos='fade-up'>The core team at Sherpa Labs has in an individual capacity either worked with, or consulted for some of the most successful companies, non-profits & fast growing startups in the world.</h4>
                    <div class="row align-items-center logo-background logo-top">
                        <div class="col-md-3 scroll-animation" data-aos='fade-right'>
                            <img src="../assets/images/citi-nobg.png" alt="Client"/>
                        </div>
                        <div class="col-md-3 scroll-animation" data-aos='fade-up'>
                            <img src="../assets/images/unicef-nobg.png" alt="Client"/>
                        </div>
                        <div class="col-md-3 scroll-animation"  data-aos='fade-down'>
                            <img src="../assets/images/Reed-nobg.png" alt="Client"/>
                        </div>
                        <div class="col-md-3 scroll-animation" data-aos='fade-left'>
                            <img src="../assets/images/tw-nobg.png" alt="Client"/>
                        </div>
                        <div class="col-md-3 scroll-animation" data-aos='fade-right'>
                            <img src="../assets/images/mckinsey_nobg.png" alt="Client"/>
                        </div>
                        <div class="col-md-3 scroll-animation" data-aos='fade-up'>
                            <img src="../assets/images/spendflo-nobg.png" alt="Client"/>
                        </div>
                        <div class="col-md-3 scroll-animation"  data-aos='fade-down'>
                            <img src="../assets/images/nokia-nobg.png" alt="Client"/>
                        </div>
                        <div class="col-md-3 scroll-animation" data-aos='fade-left'>
                            <img src="../assets/images/intel-nobg.png" alt="Client"/>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-left'>
                            <img  src="../assets/images/bcg_nobg.png" alt="Client"/>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-left'>
                            <img  src="../assets/images/axis_nobg.png" alt="Client"/>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-left'>
                            <img src="../assets/images/sedin-nobg.png" alt="Client"/>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-left'>
                            <img src="../assets/images/gsk_nobg.png" alt="Client"/>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}


