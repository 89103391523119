import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function EngagementModel() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="resume-area page-section scroll-to-page" id="engagement">
        <div className="custom-container">
            <div className="resume-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-briefcase"></i> Engagement model
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>Flexible options</h1>
                </div>

                {/* <div className="resume-timeline"> */}
                <ul>
                    <li className="item scroll-animation" data-aos='fade-right'>
                        <h2><strong>On-Demand:</strong> Get access to a community of freelance developers.</h2>

                    </li>
                    <li className="item scroll-animation" data-aos='fade-left'>

                        <h2><strong>Full-time Hire:</strong> Build a strong in-house engineering team.</h2>

                    </li>
                    <li className="item scroll-animation" data-aos='fade-right'>

                        <h2><strong>On Contract:</strong> A full-time developer working exclusively for you, with the flexibility of a contract style engagement.</h2>

                    </li>
                    </ul>
                </div>

            </div>
        {/* </div> */}
    </section>
  )
}
