import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function WhyUs() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="resume-area page-section scroll-to-page" id="why">
        <div className="custom-container">
            <div className="resume-content content-width">
                <div className="section-header">
                    {/*<h4 className="subtitle scroll-animation" data-aos='fade-up'>*/}
                    {/*    <i className="las la-briefcase"></i> A trusted outsourcing Partner*/}
                    {/*</h4>*/}
                    <h1 className="scroll-animation" data-aos='fade-up'>A trusted <span>outsourcing partner</span></h1>
                </div>

                {/* <div className="resume-timeline"> */}
                <ul>
                    <li className="item scroll-animation" data-aos='fade-right'>
                        <h2>Who can &lt;lead/architect/manage&gt; your software development.</h2>

                    </li>
                    <li className="item scroll-animation" data-aos='fade-left'>

                        <h2>Who empowers you to audition your on-demand talent before you hire.</h2>

                    </li>
                    <li className="item scroll-animation" data-aos='fade-right'>

                        <h2>Who pushes you to consistently scale, while simultaneously growing your in-house engineering team.</h2>

                    </li>
                    </ul>
                </div>

            </div>
        {/* </div> */}
    </section>
  )
}
