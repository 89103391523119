import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Partner() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="resume-area page-section scroll-to-page" id="partner">
        <div className="custom-container">
            <div className="resume-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-briefcase"></i> A partner, not a vendor
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>Consider us an extension of your <span>Engineering team</span></h1>
                </div>

                {/* <div className="resume-timeline"> */}
                <ul>
                    <li className="item scroll-animation" data-aos='fade-right'>
                        <h2>We’ll review your platform and code base. Set you up for exponential growth.</h2>

                    </li>
                    <li className="item scroll-animation" data-aos='fade-left'>

                        <h2>We’ll bring in best practices of agile methodology to your startup world, ensuring higher ROI.</h2>

                    </li>
                    <li className="item scroll-animation" data-aos='fade-right'>

                        <h2>We’ll help you build an optimal plan for engineering talent management.</h2>

                    </li>
                    </ul>
                </div>

            </div>
        {/* </div> */}
    </section>
  )
}
