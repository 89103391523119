import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import {Link} from "react-router-dom";

export default function HerosecTraining() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="hero-section page-section scroll-to-page" id="home">
        <div className="custom-container">
            <div className="hero-content content-width">
                <div className="section-header">
                    <Link to="/developers">
                        <h2 className="subtitle selected scroll-animation" data-animation="fade_from_bottom" data-aos='fade-up'>
                            <i className="las la-home"></i> For Developers
                        </h2>
                    </Link>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Link to="/" target="_blank">
                        <h2 className="subtitle scroll-animation" data-animation="fade_from_bottom" data-aos='fade-up'>
                            <i className="las la-home"></i> For Startups
                        </h2>
                    </Link>
                    <h1 className="developer-heading scroll-animation" data-aos='fade-up'>Get hands-on Product Development experience with an emphasis on the highest coding standards</h1>
                </div>
                <p className="scroll-animation" data-aos='fade-up'>Founded by <span className="green">ThoughtWorks Alumni</span> - Pioneers of Agile methodology of software development</p>
                {/*<a href="#clients"*/}
                {/*    className="go-to-project-btn scroll-to scroll-animation"*/}
                {/*     data-aos='fade-up'>*/}
                {/*    <img src="../assets/images/clients.png" alt="Rounded Text"/>*/}
                {/*    <i className="las la-arrow-down"></i>*/}
                {/*</a>*/}
                {/*<div className="facts d-flex">*/}
                {/*    <div className="left scroll-animation" data-aos='fade-right'>*/}
                {/*        <h1>10+</h1>*/}
                {/*        <p>Clients</p>*/}
                {/*    </div>*/}
                {/*    <div className="right scroll-animation" data-aos='fade-left'>*/}
                {/*        <h1>30+</h1>*/}
                {/*        <p>projects completed</p>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    </section>
  )
}
