import React from 'react'
import Lsb from './Global-content/Lsb'
import Herosec from './Main-Components/Herosec'
import WhyUs from './Main-Components/why-us'
import Service from './Main-Components/Service'
import Clientlogos from './Main-Components/Clientlogos'
import Contact from './Main-Components/Contact'
import Partner from "./Main-Components/partner";
import TrainingProgram from "./Main-Components/training-program";
import EngagementModel from "./Main-Components/engagement-model";
import {Link} from "react-router-dom";

export default function Main() {
  return (
    <main className="drake-main">
        <div id="smooth-wrapper">
            <div id="smooth-content">

                <Lsb/> {/* this component will used by mobile devices */}

                <Herosec/>
                {/* <About/> */}
                <WhyUs/>
                <Service/>
                <Partner/>
                {/*<TrainingProgram/>*/}
                <EngagementModel/>
                <Clientlogos/>
                {/*<Testimonials/>*/}
                {/*<Pricing/>*/}
                <Contact/>

                <section className="hero-section page-section scroll-to-page">
                    <div className="custom-container">
                        <div className="hero-content content-width">
                            <div className="section-header">
                                <Link to="/developers" target="_blank">
                                    <h2 className="subtitle scroll-animation "
                                        data-animation="fade_from_bottom" data-aos='fade-up'>
                                        <i className="las la-home"></i> For developers
                                    </h2>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </div>
    </main>
  )
}
