import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function TrainingExperience() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="resume-area page-section scroll-to-page" id="experience">
        <div className="custom-container">
            <div className="resume-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-briefcase"></i>A unique training program
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>How are we different?</h1>
                </div>

                {/* <div className="resume-timeline"> */}
                <ul>
                    <li className="item scroll-animation" data-aos='fade-right'>
                        <h2>Our program combines <span className="green">real-world product development</span>, and best practices of project delivery.</h2>

                    </li>
                    <li className="item scroll-animation" data-aos='fade-left'>

                        <h2>Focus is to help engineers become <span className="green">constructive self-learners.</span></h2>

                    </li>
                    <li className="item scroll-animation" data-aos='fade-right'>

                        <h2>Run by a team of <span className="green">ThoughtWorks Alumni</span> who are known for their exceptional coding skills and delivery excellence. </h2>

                    </li>
                    </ul>
                </div>

            </div>
        {/* </div> */}
    </section>
  )
}
